import Button from 'react-bootstrap/Button'
import React from 'react'
import moment from 'moment'
import { print } from '../managers/Logger'
import ReactGA from 'react-ga'
import { Mobile, Default } from './ViewTypes'

export default class LiveViewButton extends React.Component {


	openLiveView() { 
		ReactGA.initialize('UA-139931598-1')
		ReactGA.event({
				category: 'Event',
				action: 'live_view_click',
				label: this.props.event.id.toString()
		})
		window.location.href = this.props.event.live_location
	}

	getButton(overrideStyle, isLive, timeTilEvent) { 
		if (isLive) { 
			return <Button style={{...{justifyContent: 'center'}, ...overrideStyle}}
					variant="outline-danger"
					onClick={() => this.openLiveView()}>Live View</Button>
		} else { 
			return <Button style={{...{justifyContent: 'center'}, ...overrideStyle}}
						variant="outline-danger"
						disabled>Live view in {timeTilEvent}</Button>
		}
	}

	render() { 
		// print("=========rendering live button view======")
		// print(this.props.event.live_location)
		const eventNotOver = moment(this.props.event.end_date).isAfter(moment.now())
		const isLive = moment(this.props.event.start_date).isBefore(moment.now()) && moment(this.props.event.end_date).isAfter(moment.now())
		const timeTilEvent = moment(this.props.event.start_date).toNow('hh')

		
		if (this.props.event.live_location !== undefined && this.props.event.live_location !== null 
			&& this.props.event.live_location !== '' && eventNotOver) { 
				return <div style={this.props.divStyle}> 
						{this.getButton(this.props.textStyle, isLive, timeTilEvent)}
				</div>
		}


		return null
	}
}